<template>
  <div id="container">
    <div class="header">
      <van-nav-bar
        title="学生缴费"
        style="background: #01978a"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </div>

    <div class="content">
      <div class="item" v-for="(item, index) in ListData" :key="index">
        <h3 name="title">{{ item.title }}</h3>
        <p>金额详细：{{ item.amountDetail }} 元</p>
        <p>总金额：{{ item.amountTotal }} 元</p>

        <van-button
          block
          plain
          type="primary"
          style="backgroud: #07c160"
          native-type="submit"
          v-show="item.payStatus == 'True'"
          disabled
        >
          <h3>已缴费</h3>
        </van-button>

        <van-button
          block
          plain
          type="primary"
          style="backgroud: #07c160"
          native-type="submit"
          v-show="item.payStatus == 'False'"
          @click="
            pay(item.title, item.amountTotal, item.payTypeCode, item.codeList)
          "
        >
          <h3>缴费</h3>
        </van-button>
      </div>
      <div v-show="ListData==null">
              <van-button
            block
            plain
            type="primary"
            style="backgroud: #07c160;  margin-left: 16px；" 
            native-type="submit"            
            @click="H5pay()"
          >
            <h3>无缴费项，返回首页</h3>
          </van-button>
      </div>
      
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      ApiUrl: localStorage.getItem("ApiUrl"),
      ListData: [],
    };
  },
  created() {
    document.title = "学生管理平台缴费";


    this.Code = sessionStorage.getItem("Code");
    this.name = sessionStorage.getItem("Name");
    this.IDcard = sessionStorage.getItem("IDcard");
    this.Mobile = sessionStorage.getItem("Mobile");

    this.getInfo();
  },
  methods: {
    onClickLeft() {
      console.log("返回");
      this.$router.go(-1);
    },

    getInfo() {
      const forms = new FormData();
      forms.append("Mobile", this.Mobile),
        forms.append("name", this.name),
        this.$axios
          .post(this.ApiUrl + "Admin/Student/API_GetPaymentA", forms, {
            headers: {
              "Content-type": "multipart/form-data", // 修改内容类型
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.data == undefined) {
              Toast("无数据");
            }
            this.ListData = res.data.data;
            console.log(this.ListData);
            //如果传入的响应状态码为0，则成功将文件发送给后台
          })
          .catch((err) => {
            console.log(err);
            Toast(err);
          });
    },

    H5pay() {
            this.$router.push("/PaymentInfo");
      // window.location.href= "http://schoolmanager.zhimin.group/result?Code="+ this.Code+"&&Name="+this.name
      // this.$router.push("/Result");
      // const postData = {
      //                 billDesc : value,
      //                 counterNo:"123",
      //                 totalAmount:amountTotal*100,
      //                 flag:"PayD",

      // }; // post请求的数据，可自行定义
      // this.$axios({
      //     url: 'http://payapi.zhimin.group/api/BNSPay/GetQRcode',
      //     method: 'post',
      //     data: postData
      // }).then((result) => {
      //     console.log(result)

      // }).catch((err) => {
      //     console.log(err)
      //     Toast("失败！请退出后重试，多次失败请联系管理员处理。")
      // })
    },

    pay(title, amount, PayTypeCode, codelist) {
      const forms = new FormData();
      forms.append("name", this.name),
        forms.append("Code", this.Code), //学号
        forms.append("title", title), //缴费项名称
        forms.append("codelist", codelist), //缴费项编号
        forms.append("amount", amount), //总金额
        forms.append("PayTypeCode", PayTypeCode),
        forms.append("IDcard", this.IDcard), 
        forms.append("Mobile", this.Mobile),
        this.$axios
          .post(this.ApiUrl + "Admin/Student/API_Pay", forms, {
            headers: {
              "Content-type": "multipart/form-data", // 修改内容类型
            },
          })
          .then((res) => {
            console.log(res);

            let resobj = JSON.parse(res.data.data);
            console.log(resobj);

            console.log(resobj.billQRCode);
            window.location.href = resobj.billQRCode;
            //如果传入的响应状态码为0，则成功将文件发送给后台
          })
          .catch((err) => {
            console.log(err);
            Toast(err);
          });
    },

    afterRead(file) {
      console.log(file);
      const forms = new FormData();
      forms.append("file", file.file);
    },
  },
};
</script>

<style scoped>
.header {
  height: 6vh;
  color: cornsilk;
  font-size: 2vh;

  margin-left: 3vw;
}

.content {
  margin-top: 6vh;
  height: 60vh;
}
.item {
  margin: 10px;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 999;
}

.header /deep/ .van-nav-bar__title {
  color: white !important;
  font-size: 16px;
}
.header /deep/ .van-nav-bar .van-icon {
  color: white !important;
}
</style>



